import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { LIMITS } from '../../../screensSettings';
import { orderState, vmState } from '../../../state/recoilState';
import { Order, ScreenWithTimerProps, VendingMachine } from '../../../types';
import { cltext, getVmDetails, screenLog } from '../../../utils/general';
import { setOrderAmount } from '../../../utils/payments';
import { trackEvent, trackPageView } from '../../../utils/tracking';
import { Button } from '../../common/Button';
import ButtonBox from '../../common/ButtonBox';
import ContentBox from '../../common/ContentBox';
import ErrorText from '../../common/ErrorText';
import FeedbackAndReceipt from '../../common/FeedbackAndReceipt';
import Input from '../../common/Input';
import InputWrapper from '../../common/InputWrapper';
import PaymentButtons from '../../common/PaymentButtons/PaymentButtons';
import PriceBox from '../../common/PriceBox';
import PromoText from '../../common/PromoText';
import ScreenTitle from '../../common/ScreenTitle';
import ScreenWrapper from '../../common/ScreenWrapper';

type PaymentScreenProps = ScreenWithTimerProps;

const PayText = styled.div`
    margin: 0.25rem 0 0 0;
    font-size: min(3.9vw, 25px);
    font-weight: medium;
    font-family: 'Commissioner';
    color: #5c81ed;
    text-align: center;
`;

const PriceExplanation = styled.div`
    margin: 1vh 0 0 0;
    font-size: min(4.4vw, 26px);
    font-weight: medium;
    color: rgb(252 0 0);
    text-align: center;
    line-height: normal;
`;

const PaymentCT5Screen = (props: PaymentScreenProps) => {
    const {
        onCancel,
        showFeedback,
        onFeedback,
        showReceipt,
        onGetReceipt,
        onTimerTimeoutHandler,
    } = props;

    const { t } = useTranslation();

    const vm = useRecoilValue<VendingMachine>(vmState);
    const [order, setOrder] = useRecoilState<Order>(orderState);

    const [amount, setAmount] = useState<number>();
    const [error, setError] = useState<string | null>(null);
    const [showPaymentButton, setShowPaymentButton] = useState<boolean>(false);
    const [showPromoText] = useState<boolean>(
        Boolean(vm?.campaign?.message && vm?.campaign?.message.length > 0)
    );
    const [inputNumber, setInputNumber] = useState<number>(0);
    const maxLimit = Math.min(vm.max_credit_app, LIMITS.PAYMENT_CT5.MAX);
    const [amountValidated, setAmountValidated] = useState<boolean>(false);
    const [amountSent, setAmountSent] = useState<number>(0);
    const { isCT5 } = getVmDetails(vm);

    useEffect(() => {
        trackPageView('payment CT5', { orderId: order.id, ...order });
        screenLog('Payment CT5');
    }, []);

    useEffect(() => {
        if (inputNumber > maxLimit) {
            setError(
                `${t('The entered amount is too big.')} ${t(
                    'Max amount is'
                )} ${maxLimit} ${t(`Currency.${vm?.currency_code}`)}`
            );
        }
        if (inputNumber < LIMITS.PAYMENT_CT5.MIN) {
            setError(
                `${t('The entered amount is too small.')} ${t(
                    'Min amount is'
                )} ${LIMITS.PAYMENT_CT5.MIN} ${t(
                    `Currency.${vm?.currency_code}`
                )}`
            );
        }
    }, [inputNumber, t]);

    return (
        <ScreenWrapper>
            <ScreenTitle>{t('Enter exact amount')}</ScreenTitle>
            <ContentBox>
                <PriceExplanation>
                    {t('Enter exact description')}
                </PriceExplanation>
                <InputWrapper>
                    <Input
                        type="number"
                        inputMode="decimal"
                        // min={LIMITS.PAYMENT_CT5.MIN}
                        max={LIMITS.PAYMENT_CT5.MAX}
                        step="1"
                        placeholder={t<string>('Amount in currency', {
                            currency: t(`Currency.${vm?.currency_code}`),
                        })}
                        value={amount && amount > 0 ? amount : ''}
                        onChange={(event) => {
                            const input = event.target.value.replace(',', '.');
                            let enteredAmount = Number(input);
                            // check for VM limits
                            if (enteredAmount > maxLimit) {
                                setInputNumber(enteredAmount);
                                enteredAmount = maxLimit;
                                setShowPaymentButton(true);
                            } else {
                                setInputNumber(enteredAmount);
                                setError('');
                                setShowPaymentButton(true);
                            }
                            if (enteredAmount <= LIMITS.PAYMENT_CT5.MIN) {
                                setInputNumber(enteredAmount);
                                setShowPaymentButton(false);
                            }

                            const discount = vm?.campaign?.discount_percentage
                                ? 1 - vm?.campaign?.discount_percentage / 100
                                : 1;
                            setAmount(enteredAmount);
                            const realAmount = enteredAmount * 100 * discount;
                            trackEvent('Input value', {
                                input_value: enteredAmount,
                                amount_to_DB: realAmount,
                            });
                            setOrderAmount(
                                order.id as string,
                                realAmount,
                                (
                                    validationResult: boolean,
                                    requestAmount: string = 'n/a'
                                ) => {
                                    cltext(
                                        ` >>> server validation callback. sent: ${realAmount}, validated: ${validationResult}, \n\trequest amount: ${requestAmount}`
                                    );
                                    setAmountValidated(validationResult);
                                    setAmountSent(realAmount);
                                }
                            );
                            setOrder({
                                ...order,
                                amount: realAmount,
                            });
                        }}
                    />

                    <ErrorText className="error">{error}</ErrorText>
                </InputWrapper>

                {showPromoText && (
                    <PromoText>{vm?.campaign?.message}</PromoText>
                )}

                <div>
                    <PriceBox>
                        <span id="price">
                            {(order?.amount / 100).toFixed(2)}
                        </span>{' '}
                        {t(`Currency.${vm?.currency_code}`)}
                    </PriceBox>

                    {/* {Boolean(vm.campaign?.discount_percentage) && (
            <DiscountMessage>
              {t('Your discount is ')}
              {vm.campaign?.discount_percentage}%
            </DiscountMessage>
          )} */}
                </div>

                {showPaymentButton && amountValidated ? (
                    <div>
                        <PayText>{t('Order payment')}</PayText>
                        <ButtonBox>
                            <PaymentButtons />
                            {!isCT5 && (
                                <Button onClick={onCancel}>
                                    {t('Cancel')}
                                </Button>
                            )}
                        </ButtonBox>
                    </div>
                ) : (
                    <FeedbackAndReceipt
                        showReceipt={showReceipt}
                        showFeedback={showFeedback}
                        onCancelClick={onCancel}
                        onReceiptClick={onGetReceipt}
                        onFeedbackClick={onFeedback}
                    />
                )}
            </ContentBox>
        </ScreenWrapper>
    );
};

export default PaymentCT5Screen;
